import styled from "styled-components";
import pictureImages from './assets/picturewall.jpg?w=1000;1600';
import toast from "react-hot-toast";

import media from "../../../utilities/media.js";
import {useEffect, useState} from "react";
import Sparkles from "../../atoms/Sparkles/Sparkles.jsx";

const PictureWallStyled = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const PictureWallContent = styled.div`
  position: relative;
  width: 100%;

  // ${media.lessThan('xl')} {
  //   transform: translateY(-10%) scale(1.8);
  //   left: 10%;
  // }
`;

const PictureWallImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const PictureLink = styled.div`
  position: absolute;
  cursor: pointer;
  z-index: 2;
`;

const PictureWall = ({onSolve}) => {
  const [solved, setSolved] = useState(false);

  useEffect(() => {
      if (solved) {
        setTimeout(() => {
          onSolve();
        }, 1500)
      }
    },
    [solved]
  );

  return (
    <PictureWallStyled>
      <PictureWallContent>
        {solved ? (
          <Sparkles/>
        ) : null}
        <PictureWallImg src={pictureImages[1]}/>
        <PictureLink style={{
          top: '10%',
          left: '13%',
          width: '30%',
          height: '40%'
        }} onClick={() => {
          toast('Gemeinsam bringen wir Freude in die Welt, aber das Christkind meinte ich nicht.');
        }}/>
        <PictureLink style={{
          top: '10%',
          left: '53%',
          width: '36%',
          height: '45%'
        }} onClick={() => {
          toast('Der wird Dir nicht weiter helfen, er ist im Winter einfach nicht zu gebrauchen.');
        }}/>
        <PictureLink style={{
          top: '54%',
          left: '6%',
          width: '32%',
          height: '42%'
        }} onClick={() => {
          setSolved(true);
        }}/>
        <PictureLink style={{
          top: '60%',
          left: '46%',
          width: '48%',
          height: '35%'
        }} onClick={() => {
          toast('Mein bester und zuverlässigster Mitarbeiter.');
        }}/>
      </PictureWallContent>
    </PictureWallStyled>
  )
}

export default PictureWall;
import Stage from "../../base/Stage/Stage.jsx";
import Marker from "../../../Marker.jsx";
import Details from "../../base/Details/Details.jsx";
import AnimatedRoute from "../../base/AnimatedRoute/AnimatedRoute.jsx";
import {useContext, useEffect, useState} from "react";
import {useLocation} from "wouter";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";

import introAudio from './audio/level4_intro.mp3';
import Puzzle from "./Puzzle.jsx";
import styled from "styled-components";
import {headline2} from "../../../styles/typography.js";

const NumberDisplay = styled.div`
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 100;
  padding: 10px;
  ${headline2};
  background-color: ${({theme}) => theme.colors.primary};
  color: ${({theme}) => theme.colors.white};
`;

const Level4 = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [found, setFound] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });
  const [solved, setSolved] = useState(false);
  const [location, setLocation] = useLocation();
  const {setLevel, saveLevelTime} = useContext(AppContext);

  useEffect(() => {
    if (showIntro && location === '/') {
      setTimeout(() => {
        setLocation('/intro');
        setShowIntro(false);
      }, 1000);
    }

  }, [showIntro])

  useEffect(() => {
    saveLevelTime(1);
  }, [])

  const numberFound = Object.values(found).filter((f) => f).length;

  const foundPart = (number) => {
    const newFound = {...found}
    newFound[number] = true;
    setFound(newFound);
    const numberFound = Object.values(newFound).filter((f) => f).length;
    if (numberFound >= 5) {
      setLocation('/details/1');
    }
  }

  return (
    <>
      <NumberDisplay>
        Teile gefunden: {numberFound}/5
      </NumberDisplay>
      <Stage lightsOn>
        <Marker
          top={50}
          left={27}
          href={'/intro'}
        />
        <Marker
          top={40}
          left={70}
          message="Rudolphine kann Dir jetzt auch nicht helfen"
        />
        <Marker
          top={50}
          left={60}
          message="Auf dem Mischpult ist nichts."
        />
        <Marker
          top={40}
          left={80}
          message="Ohh das ist wirklich ein schöner Weihnachtsbaum."
        />
        <Marker
          top={40}
          left={45}
          message="Hier versteckt sich kein Zettel."
        />
        <Marker
          top={65}
          left={40}
          message="Ja, Du hast ein Teil gefunden!"
          onClick={() => {
            foundPart(1);
          }}
        />
        <Marker
          top={75}
          left={62}
          message="Ja, Du hast ein Teil gefunden!"
          onClick={() => {
            foundPart(2)
          }}
        />
        <Marker
          top={65}
          left={46}
          message="Ja, Du hast ein Teil gefunden!"
          onClick={() => {
            foundPart(3)
          }}
        />
        <Marker
          top={62}
          left={50}
          message="Ja, Du hast ein Teil gefunden!"
          onClick={() => {
            foundPart(4)
          }}
        />
        <Marker
          top={80}
          left={72}
          message="Ja, Du hast ein Teil gefunden!"
          onClick={() => {
            foundPart(5)
          }}
        />
        <Marker
          top={76}
          left={67}
          message="Auf dem Zettel ist nichts mehr zu erkennen."
        />
        <Marker
          top={80}
          left={63}
          message="Eine Wunschliste von einem Kind, wie süß. Gut, dass noch alles lesbar ist."
        />
        <Marker
          top={82}
          left={25}
          message="Das ist nur eine Grußkarte."
        />
        <Marker
          top={55}
          left={13}
          message="Das ist ein Keyboard. Aber da sind keine Noten..."
        />
      </Stage>
      <AnimatedRoute path={`/intro`}>
        <Details
          title="Kapitel 4: Die Puzzle-Sinfonie"
          interstitial
          audio={introAudio}
          text={`<p>
            Weihnachtsmann aus dem Radio:<br/>
            „Toll, dass Du noch da bist! Ich spüre ganz deutlich, dass du auf diesem Wege meiner Rettung 
            langsam näher kommst. Vielleicht kann Dir als nächstes mein Keyboard weiterhelfen. Wenn ein 
            bestimmtes Weihnachtslied gespielt wird, öffnet sich automatisch ein Geheimfach.</p> 
            <p>
            Aber welches Lied musste noch einmal gespielt werden? Ich befürchte die passenden Noten wurden 
            beim Blitzeinschlag zerfetzt und im Raum verteilt...“
            </p>`}
        />
      </AnimatedRoute>
      <AnimatedRoute path={`/details/1`}>
        <Details
          title="Das Notenblatt"
        >
          <Puzzle onSolved={() => {
            setLevel(5);
            saveLevelTime(2);
            setLocation('/');
          }}/>
        </Details>
      </AnimatedRoute>#
    </>
  )
}

export default Level4;
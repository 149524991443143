import Draggable from "react-draggable";
import styled from "styled-components";

import backgroundImages from "./assets/puzzle_background.jpg?w=800;1200";

import puzzle1Image from "./assets/puzzle_1.png?w=601";
import puzzle2Image from "./assets/puzzle_2.png?w=600";
import puzzle3Image from "./assets/puzzle_3.png?w=600";
import puzzle4Image from "./assets/puzzle_4.png?w=600";
import puzzle5Image from "./assets/puzzle_5.png?w=600";
import puzzle6Image from "./assets/puzzle_6.png?w=600";

import {useCallback, useEffect, useState} from "react";
import PuzzlePart from "./PuzzlePart.jsx";
import Sparkles from "../../atoms/Sparkles/Sparkles.jsx";

const PuzzleStyled = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  pointer-events: none;
`;

const PuzzleImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
  pointer-events: none;
`;


const Puzzle = ({onSolved}) => {
  const [containerHeight, setContainerHeight] = useState(10);
  const [solved, setSolved] = useState(false);
  const [solvedParts, setSolvedParts] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  });

  const containerRef = useCallback(node => {
    console.log('Callback');
    if (node !== null) {
      setContainerHeight(node.getBoundingClientRect().width);
    }
  }, []);

  const step = Math.round(containerHeight / 10);

  console.log('step', step)

  useEffect(() => {
    // If all parts are solved, call onSolved
    if (Object.values(solvedParts).every((solvedPart) => solvedPart)) {
      setSolved(true);
      setTimeout(() => {
        onSolved();
      }, 2000);
    }
  }, [solvedParts]);

  return (
    <PuzzleStyled ref={containerRef}>
      {solved ? <Sparkles/> : null}
      <PuzzleImg src={backgroundImages[1]}/>
      <PuzzlePart
        step={step} width="30%" height="30%" src={puzzle1Image}
        handles={[{
          width: '80%',
          height: '60%',
          top: '20%',
          left: '0',
        }]}
        start={{
          x: 6,
          y: 0,
        }}
        solution={{
          x: 0,
          y: 0,
        }}
        setSolved={(partSolved) => setSolvedParts({...solvedParts, 1: partSolved})}
      />
      <PuzzlePart
        step={step} width="40%" height="30%" src={puzzle2Image}
        style={{
          zIndex: 100,
        }}
        handles={[{
          width: '60%',
          height: '50%',
          top: '20%',
          left: '20%',
        }]}
        start={{
          x: 5,
          y: 5,
        }}
        solution={{
          x: 2,
          y: 0,
        }}
        setSolved={(partSolved) => setSolvedParts({...solvedParts, 2: partSolved})}
      />
      <PuzzlePart
        step={step} width="60%" height="30%" src={puzzle3Image}
        handles={[{
          width: '70%',
          height: '35%',
          top: '15%',
          left: '30%',
        }, {
          width: '30%',
          height: '75%',
          top: '15%',
          left: '70%',
        }]}
        start={{
          x: 0,
          y: 4,
        }}
        solution={{
          x: 4,
          y: 0,
        }}
        setSolved={(partSolved) => setSolvedParts({...solvedParts, 3: partSolved})}
      />
      <PuzzlePart
        step={step} width="70%" height="30%" src={puzzle4Image}
        handles={[{
          width: '70%',
          height: '60%',
          top: '10%',
          left: '10%',
        }]}
        start={{
          x: 0,
          y: 0,
        }}
        solution={{
          x: 2,
          y: 3,
        }}
        setSolved={(partSolved) => setSolvedParts({...solvedParts, 4: partSolved})}
      />
      <PuzzlePart
        step={step} width="70%" height="30%" src={puzzle5Image}
        handles={[{
          width: '60%',
          height: '50%',
          top: '30%',
          left: '35%',
        }]}
        start={{
          x: 0,
          y: 3,
        }}
        solution={{
          x: 3,
          y: 5,
        }}
        setSolved={(partSolved) => setSolvedParts({...solvedParts, 5: partSolved})}
      />
      <PuzzlePart
        step={step} width="50%" height="30%" src={puzzle6Image}
        handles={[{
          width: '70%',
          height: '50%',
          top: '31%',
          left: '0',
        }]}
        start={{
          x: 5,
          y: 2
        }}
        solution={{
          x: 0,
          y: 4,
        }}
        setSolved={(partSolved) => setSolvedParts({...solvedParts, 6: partSolved})}
      />
    </PuzzleStyled>
  )
}

export default Puzzle;
import styled from "styled-components";
import radioImages from '../assets/01_radio_blanko.jpg?w=1000;1600';
import volume_1 from './assets/volume_1.png';
import volume_2 from './assets/volume_2.png';
import volume_3 from './assets/volume_3.png';
import volume_4 from './assets/volume_4.png';
import volume_5 from './assets/volume_5.png';
import plus from './assets/plus.png';
import plusHi from './assets/plus_hi.png';
import minus from './assets/minus.png';
import minusHi from './assets/minus_hi.png';

import media from "../../../utilities/media.js";
import {useEffect, useState} from "react";
import Sparkles from "../../atoms/Sparkles/Sparkles.jsx";

const RadioStyled = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const RadioContent = styled.div`
  position: relative;
  width: 100%;

   ${media.lessThan('xl')} {
     transform: translateY(-10%) scale(1.8);
     left: 10%;
   }
`;

const RadioImg = styled.img`
  display: block;
  width: 100%;
  height: auto;

`;

const VolumeImg = styled.img`
  position: absolute;
  top: 63%;
  left: 32%;
  width: 10%;
  background-color: black;
`;

const VolumeButton = styled.button`
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  cursor: pointer;
`;


const Plus = styled(VolumeButton)`
  top: 69.8%;
  left: 38%;
  width: 5%;
  padding-bottom: 5%;
  background-image: url(${plus});
  
  &:active {
    background-image: url(${plusHi});
  }
`;

const Minus = styled(VolumeButton)`
  top: 69.8%;
  left: 33%;
  width: 5%;
  padding-bottom: 5%;
  background-image: url(${minus});
  
  &:active {
    background-image: url(${minusHi});
  }
`;

const Radio = ({ onSolve }) => {
  const [solved, setSolved] = useState(false);
  const [volume, setVolume] = useState(1);

  const volumeImages = [volume_1, volume_2, volume_3, volume_4, volume_5];
  const currentVolumeImage = volumeImages[volume - 1];

  const changeVolume = (direction) => {
    if (direction === 'up') {
      if (volume === 5) {
        return;
      }
      setVolume(volume + 1);
    } else {
      if (volume === 1) {
        return;
      }
      setVolume(volume - 1);
    }
  }

  useEffect(() => {
    if (volume === 5 && onSolve) {
      setSolved(true);
      setTimeout(() => {
        onSolve();
      }, 1500);
    }
  }, [volume]);

  return (
    <RadioStyled>
      <RadioContent>
        {solved ? (
          <Sparkles />
        ) : null}
        <RadioImg src={radioImages[1]}/>
        <VolumeImg src={currentVolumeImage}/>
        <Plus onClick={() => changeVolume('up')}/>
        <Minus onClick={() => changeVolume('down')}/>
      </RadioContent>
    </RadioStyled>
  )
}

export default Radio;
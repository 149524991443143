import styled from "styled-components";
import Draggable from "react-draggable";
import ReglerImage from "./assets/mischpult_regler.svg?react";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import Lamp from "./Lamp.jsx";

const ReglerStyled = styled.div`
  position: absolute;
  top: 10%;
  left: 10%;
  width: 10%;
  height: 84%;
`;

const ReglerControl = styled.div`
  cursor: grab;
  width: 100%;
`;

const LampContainer = styled.div`
  position: absolute;
  width: 20%;
  height: 5%;
  top: -4%;
  left: 50%;
  transform: translateX(-50%);
`;

const Regler = (
  {
    left = '7%',
    start = 0,
    active = false,
    correct = -1,
    onSolve = () => {
    }
  }) => {
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const [position, setPosition] = useState(0);
  const [draggablePosition, setDraggablePosition] = useState({x: 0, y: 0});

  useLayoutEffect(() => {
    setTimeout(() => {
      if (containerRef.current) {
        setContainerHeight(containerRef.current.clientHeight);
        setPosition(containerRef.current.clientHeight / 11 * start);
      }
      ;
    }, 300);
  }, []);

  const step = Math.round(containerHeight / 11);

  // console.log(step);

  return (
    <ReglerStyled ref={containerRef} style={{left}}>
      <LampContainer>
        <Lamp on={active}/>
      </LampContainer>
      <Draggable
        bounds="parent"
        grid={[1, step]}
        // onStart={() => active}
        position={draggablePosition}
        onDrag={(e, data) => {
          const {x, y} = data;
          setDraggablePosition({x, y})
        }}
        onStop={(e, data) => {
          if (active) {
            const value = Math.round(data.y / -step) + 1;
            if (value === correct) {
              onSolve();
            }
          } else {
            setPosition(step * start);
            setDraggablePosition({x: 0, y: 0})
          }

        }}
      >
        <ReglerControl style={{position: 'absolute', left: 0, bottom: position}} $active={active}>
          <ReglerImage/>
        </ReglerControl>
      </Draggable>
    </ReglerStyled>
  )
}

export default Regler;
import StartScreen from "../StartScreen/StartScreen.jsx";
import Button, {ButtonStyled} from "../../atoms/Button/Button.jsx";
import {useContext, useEffect, useState} from "react";
import {useLocation} from "wouter";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";
import styled from "styled-components";
import media from "../../../utilities/media.js";
import AudioSwitch from "../../atoms/AudioSwitch/AudioSwitch.jsx";

const VideoContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const VideoStyled = styled.video`
  width: 100%;

  ${media.moreThan('lg')} {
    height: 100%;
    object-fit: contain;
  }
`;

const IntroVideo = ({mute}) => {
  return (
    <VideoContainer>
      <VideoStyled src="https://rmsde.b-cdn.net/rms_escape_slideshow.mp4"
                   controls
                   autoPlay
                   muted={mute}
                   playsInline></VideoStyled>
    </VideoContainer>
  )
}

const Continue = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.moreThan('lg')} {
    bottom: auto;
    position: absolute;
    top: 10%;
    right: 0;
    left: auto;
    padding: 20px;
    border-radius: 10px;

    background-color: ${({theme}) => theme.colors.primaryTrans};
  }
`;

const ButtonRow = styled.div`
  padding-top: 20px;
`;

const Level0 = () => {
  const [showIntro, setShowIntro] = useState(false);
  const {level, setLevel} = useContext(AppContext);
  const {useAudio} = useContext(AppContext)
  const [location, setLocation] = useLocation();

  return (
    <>
      <StartScreen onStart={() => {
        setShowIntro(true);
      }}/>
      {showIntro ? (
        <>
          <IntroVideo mute={!useAudio}/>
          <Continue>
            <AudioSwitch/>
            <ButtonRow>
              <Button onClick={() => {
                setLevel(1);
                setLocation('/');
              }}>Weiter</Button>
            </ButtonRow>
          </Continue>
        </>
      ) : null}
    </>
  )
}

export default Level0;
import {MatomoProvider, createInstance, useMatomo} from '@datapunt/matomo-tracker-react'

import GlobalStyle from "./styles/globals.js";
import defaultTheme from "./styles/defaultTheme.js";
import styled, {ThemeProvider} from "styled-components";
import Footer from "./components/base/Footer/Footer.jsx";
import {Router, useLocation} from "wouter";
import {Toaster} from "react-hot-toast";
import AppContext, {AppContextProvider} from "./utilities/AppContext/AppContext.jsx";
import getLevel from "./components/levels/getLevel.js";
import {useContext, useEffect} from "react";
import {buttontext} from "./styles/typography.js";
import range from "./utilities/range.js";

const LandscapeWarning = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 1000;
  background: ${({theme}) => theme.colors.black};
  display: none;
  
  @media screen and (max-width: 767px) and (orientation: landscape) {
    display: block;
  }
`;

const LandscapeWarningText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${buttontext};
  background-color: ${({theme}) => theme.colors.primary};
  color: ${({theme}) => theme.colors.white};
  padding: 5px 10px;
`;

const Tracker = ({level}) => {
  const {trackPageView, trackEvent} = useMatomo()
  const [location,] = useLocation();

  useEffect(() => {
    trackPageView({
      href: `level-${level}${location}`,
    })
  }, [level, location])
  return null;
}

const AppMain = () => {
  const {level, setLevel, admin} = useContext(AppContext);
  const Level = getLevel(level, admin);

  return (
    <>
      <LandscapeWarning>
        <LandscapeWarningText>
          Bitte drehen Sie Ihr Gerät.
        </LandscapeWarningText>
      </LandscapeWarning>
      <Toaster toastOptions={{
        style: {
          background: defaultTheme.colors.primaryTrans,
          fontFamily: defaultTheme.fonts.Copy,
          color: '#fff',
        },
      }}/>
      <GlobalStyle/>
      <Router base="">
        <Tracker level={level}/>
        <Level/>
        <Footer>
          <select
            value={level}
            onChange={(e) => {
              setLevel(e.target.value)
            }}
          >
            {range(0, 10).map((i) => (
              <option key={i} value={i}>
                Level {i}
              </option>
            ))}
          </select>
        </Footer>
      </Router>
    </>
  )
}

const matomo = createInstance({
  urlBase: 'https://matomo.atw.io',
  siteId: 36,
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: { // optional, default value: {}
    disableCookies: true,
  }
})

const App = () =>
  (
    <MatomoProvider value={matomo}>
      <ThemeProvider theme={defaultTheme}>
        <AppContextProvider>
          <AppMain/>
        </AppContextProvider>
      </ThemeProvider>
    </MatomoProvider>
  )

export default App;

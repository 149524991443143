import Stage from "../../base/Stage/Stage.jsx";
import Marker from "../../../Marker.jsx";
import Details from "../../base/Details/Details.jsx";
import zettelImages from '../assets/02_zettel.jpg?w=1000;1600';
import AnimatedRoute from "../../base/AnimatedRoute/AnimatedRoute.jsx";
import {useContext, useEffect, useState} from "react";
import {useLocation} from "wouter";
import Stromkasten from "./Stromkasten.jsx";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";

import introAudio from './audio/level2_intro.mp3';
import stromkastenAudio from './audio/level2_stromkasten.mp3';

const Level2 = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [kastenSolved, setKastenSolved] = useState(false);
  const [location, setLocation] = useLocation()
  const {setLevel, saveLevelTime} = useContext(AppContext);

  useEffect(() => {
    if (showIntro && location === '/') {
      setLocation('/intro');
      setShowIntro(false);
    }

  }, [showIntro])

  useEffect(() => {
    saveLevelTime(1);
  }, [])

  return (
    <>
      <Stage lightsOn={kastenSolved}>
        <Marker
          top={51}
          left={55}
          href={'/details/1'}
        />
        <Marker
          top={50}
          left={27}
          href={'/intro'}
        />
        <Marker
          top={28}
          left={5}
          href={'/details/2'}
        />
        <Marker
          top={65}
          left={40}
          message="Hier steht nichts was jetzt für Dich wichtig ist."
        />
        <Marker
          top={61}
          left={50}
          message="Auf der Notiz ist nichts mehr erkennbar."
        />
        <Marker
          top={40}
          left={80}
          message="Im Weihnachtsbaum ist nichts."
        />
        <Marker
          top={40}
          left={45}
          message="Das sind nur Bilder."
        />
        <Marker
          top={55}
          left={13}
          message="Das Keyboard hat keinen Strom."
        />
      </Stage>
      <AnimatedRoute path={`/intro`}>
        <Details
          title="Kapitel 2: Der Stromkasten"
          interstitial
          audio={introAudio}
          text={`<p>Weihnachtsmann aus dem Radio:<br/>
            "Oh nein, der Blitzeinschlag hat auch einen Kurzschluss verursacht. Du solltest das Tonstudio 
            erst einmal wieder in Gang bringen und den Strom anschalten, dann siehst Du bestimmt auch besser."
          </p>`}
        />
      </AnimatedRoute>
      <AnimatedRoute path={`/details/1`}>
        <Details
          images={zettelImages}
          title="Zettel"
        />
      </AnimatedRoute>
      <AnimatedRoute path={`/details/2`}>
        <Details
          title="Der Stromkasten"
          tip={`Der Strom ist schon häufiger ausgefallen, daher hat der Weihnachtsmann eine Liste an seinem 
          Arbeitsplatz liegen, um sich die richtige Reihenfolge zum Betätigen der Sicherungen zu erinnern.`}
          tipId={2}
          audio={stromkastenAudio}
          text={`<p>
              Beachte unbedingt die richtige Reihenfolge, um meine kostbaren Geräte nicht zu zerstören. 
              Meine Notizen könnten Dir dabei helfen.
              </p>`}
        >
          <Stromkasten onSolve={() => {
            saveLevelTime(2);
            setLevel(3);
            setLocation('/');
          }}/>
        </Details>
      </AnimatedRoute>
    </>
  )
}

export default Level2;
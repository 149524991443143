import Stage from "../../base/Stage/Stage.jsx";
import Marker from "../../../Marker.jsx";
import Details from "../../base/Details/Details.jsx";
import AnimatedRoute from "../../base/AnimatedRoute/AnimatedRoute.jsx";
import {useContext, useEffect, useState} from "react";
import {useLocation} from "wouter";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";
import Mischpult1 from "./Mischpult1.jsx";
import rentierImages from './assets/04_rentier_singt.jpg?w=1000;1600';

import introAudio from './audio/level3_intro.mp3';
import rentierAudio from './audio/level3_rentier.mp3';
import RegistrationForm from "./RegistrationForm.jsx";

const Level3 = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [solved, setSolved] = useState(false);
  const [location, setLocation] = useLocation();
  const {setLevel, saveLevelTime} = useContext(AppContext);

  useEffect(() => {
    if (showIntro && location === '/') {
      setTimeout(() => {
        setLocation('/intro');
        setShowIntro(false);
      }, 1000);
    }

  }, [showIntro])

  useEffect(() => {
    saveLevelTime(1);
  }, [])

  return (
    <>
      <Stage lightsOn>
        <Marker
          top={50}
          left={27}
          href={'/intro'}
        />
        <Marker
          top={40}
          left={70}
          href={'/details/1'}
        />
        <Marker
          top={50}
          left={60}
          href={solved ? `/details/3` : `/details/2`}
        />
        <Marker
          top={40}
          left={80}
          message="Ohh das ist wirklich ein schöner Weihnachtsbaum."
        />
        <Marker
          top={40}
          left={45}
          message="Das sind nur Bilder."
        />
        <Marker
          top={80}
          left={40}
          message="Das ist einfach nur kaputt."
        />
        <Marker
          top={55}
          left={13}
          message="Das sieht aber nicht aus wie Rudolphine, oder? Dann hilft Dir das jetzt auch nicht weiter."
        />
      </Stage>
      <AnimatedRoute path={`/intro`}>
        <Details
          title="Kapitel 3: Das singende Rentier"
          interstitial
          right
          audio={introAudio}
          text={`<p>
            Weihnachtsmann aus dem Radio:<br/> 
            „Da ist ja Rudolphine! Mein überaus cleveres und so musikbegabtes Rentier! Ich hatte gerade mit ihr den 
            Weihnachtshit eingespielt, als der Knall kam. Vielleicht kann sie dir weiter helfen?“
            </p>`}
        />
      </AnimatedRoute>
      <AnimatedRoute path={`/details/1`}>
        <Details
          title="Das singende Rentier"
          images={rentierImages}
          audio={rentierAudio}
          tip="Genau hinhören, eine Zahl ist doch anders als sonst."
          tipId={3}
          text={`<p>
            Das Rentier singt!
            </p>
            <p> 
            „Kling, Glöckchen, Klingelingeling<br/>
            Kling, Glöckchen Kling<br/>
            Hell erglüh'n zehn Kerzen<br/>
            Öffnet mir die Herzen<br/>
            Will drin wohnen fröhlich<br/>
            Frommes Kind wie selig<br/>
            Kling, Glöckchen, Klingelingeling<br/>
            Kling, Glöckchen Kling“
            </p>`}
        />
      </AnimatedRoute>
      <AnimatedRoute path={`/details/2`}>
        <Details
          title="Das magische Mischpult"
          tip="Was singt Rudolphine denn da? So viele Kerzen können es doch nicht sein. Komisch…"
          tipId={4}
          text={`<p>
            Von dem Mischpult hat der Weihnachtsmann doch gesprochen. 
            Wie war das noch einmal? Die Regler müssen richtig eingestellt werden? Aber auf was? 
            </p>`}
        >
          <Mischpult1 onSolved={() => {
            saveLevelTime(2);
            setSolved(true);
            setLocation('/details/3');
          }}/>
        </Details>
      </AnimatedRoute>
      <AnimatedRoute path={`/details/3`}>
        <Details
          title="Registrierung"
          form
          text={`<p>
            Großartige Leistung! Du hast das erste Rätsel erfolgreich gemeistert!   
            </p>
            <p>
            Aber das war nur der Anfang: Registriere Dich jetzt, um Dich den wöchentlichen 
            Herausforderungen zu stellen und das Weihnachtsfest zu retten! Erspiele Dir 
            einen Platz in den TOP 10. Unter allen Teilnehmern verlosen wir 1 von 3 Sonos Era 100! 
            </p>
            <p>
            Das Abenteuer geht weiter - sei dabei!            
            </p>`}
        >
          <RegistrationForm/>
        </Details>
      </AnimatedRoute>
    </>
  )
}

export default Level3;
import Stage from "../../base/Stage/Stage.jsx";
import Marker from "../../../Marker.jsx";
import Details from "../../base/Details/Details.jsx";
import AnimatedRoute from "../../base/AnimatedRoute/AnimatedRoute.jsx";
import {useContext, useEffect, useState} from "react";
import {useLocation} from "wouter";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";

import introAudio from './audio/level7_intro.mp3';
import cordAudio from './audio/level7_cord.mp3';
import Smartphone from "./Smartphone.jsx";
import Mischpult1 from "../Level3/Mischpult1.jsx";

const Level7 = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [solved, setSolved] = useState(false);
  const [location, setLocation] = useLocation();
  const {setLevel, saveLevelTime} = useContext(AppContext);

  useEffect(() => {
    if (showIntro && location === '/') {
      setTimeout(() => {
        setLocation('/intro');
        setShowIntro(false);
      }, 1000);
    }

  }, [showIntro])

  useEffect(() => {
    saveLevelTime(1);
  }, [])

  return (
    <>
      <Stage lightsOn>
        <Marker
          top={50}
          left={27}
          href={'/intro'}
        />
        <Marker
          top={40}
          left={70}
          message={solved ? 'Rudolphine weiß nicht, was Du mit der fünf machen sollst.' : "Rudolphine hat das Handy nicht gesehen."}
        />
        <Marker
          top={50}
          left={60}
          message={solved ? null : "Du brauchst eine Zahl, um sie auf dem Mischpult einzustellen."}
          href={solved ? `/details/3` : null}
        />
        <Marker
          top={40}
          left={80}
          message="Ohh das ist wirklich ein schöner Weihnachtsbaum."
        />
        <Marker
          top={40}
          left={38}
          message="Die Tür ist immer noch verschlossen."
        />
        <Marker
          top={40}
          left={45}
          message={solved ? 'Hier kannst Du nichts mit der fünf machen.' : 'Bei den Bildern ist das Handy nicht.'}
        />
        <Marker
          top={55}
          left={13}
          message={solved ? 'Hier ist nichts.' : 'Hier ist leider kein Handy.'}
        />
        <Marker
          top={80}
          left={63}
          message={solved ? 'Hier ist nichts.' : 'Nein, hier ist das Handy auch nicht heruntergefallen.'}
        />
        <Marker
          top={45}
          left={43}
          href={'/details/1'}
        />
        <Marker
          top={82}
          left={38}
          message={solved ? 'Hier ist nichts.' : 'Hier ist kein Handy versteckt.'}
        />
      </Stage>
      <AnimatedRoute path={`/intro`}>
        <Details
          title="Kapitel 7: Der Freundschaftscode"
          interstitial
          audio={introAudio}
          text={`<p>
            Weihnachtsmann aus dem Radio:<br/>
            "Jetzt brauchen wir nur noch mein Handy. Irgendwo muss es 
            doch liegen ... hoffentlich hat der Blitz es nicht stillgelegt."
            </p>`}
        />
      </AnimatedRoute>
      <AnimatedRoute path={`/details/1`}>
        <Details
          title="Der Anrufbeantworter"
          audio={cordAudio}
          interstitial={solved}
          tip="Jetzt musst Du nur noch den Freund vom Weihnachtsmann anrufen."
          tipId={6}
          text={solved ? `<p>
Hallo! Hier spricht der Anrufbeantworter von Cord Hollender, 
Geschäftsführer Verkauf von RMS. Ich bin gerade damit beschäftigt, 
weihnachtliche Audio-Botschaften im ganzen Land zu verbreiten und 
kann deshalb nicht ans Telefon gehen. </p>
<p>Falls Du es bist, lieber Weihnachtsmann: Denk an die Nr. 5, 
wenn Du mit Deiner Weihnachtsshow die Menschen verzaubern willst. 
Ich wünsche Dir ein frohes Fest und bis bald! 
</p>` : null}
        >
          <Smartphone onSolve={() => {
            setSolved(true)
          }} levelSolved={solved}/>
        </Details>
      </AnimatedRoute>
      <AnimatedRoute path={`/details/3`}>
        <Details
          title="Das magische Mischpult"
          tip="Wie oft kam der Kuckuck heraus?"
          tipId={7}
        >
          <Mischpult1
          active={3}
          start={[9, 6, 0, 0]}
            onSolved={() => {
            saveLevelTime(2);
            setLevel(8);
            setLocation('/');
          }}/>
        </Details>
      </AnimatedRoute>
    </>
  )
}

export default Level7;
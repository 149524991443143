import Stage from "../../base/Stage/Stage.jsx";
import Details from "../../base/Details/Details.jsx";
import AnimatedRoute from "../../base/AnimatedRoute/AnimatedRoute.jsx";
import {useContext, useEffect, useState} from "react";
import {useLocation} from "wouter";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";

const Level9 = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [solved, setSolved] = useState(false);
  const [location, setLocation] = useLocation();
  const {setLevel, saveLevelTime} = useContext(AppContext);

  useEffect(() => {
    if (showIntro && location === '/') {
      setTimeout(() => {
        setLocation('/intro');
        setShowIntro(false);
      }, 1000);
    }

  }, [showIntro])

  return (
    <>
      <Stage santa>
      </Stage>
      <AnimatedRoute path={`/intro`}>
        <Details
          title="Herzlichen Glückwunsch!"
          right
          interstitial
          text={`<p>
            „Herzlichen Glückwunsch! Du hast es geschafft. Der Weihnachtsmann ist wieder 
            aufgetaucht und seine Weihnachtsshow gerettet. Jetzt drücken wir Dir nur noch 
            die Daumen für das Gewinnspiel! Frohe Weihnachten – und dreh schön Dein 
            Audio-Device auf ;-)“
            </p>`}
        />
      </AnimatedRoute>
    </>
  )
}

export default Level9;
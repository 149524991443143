import styled from "styled-components";
import phoneImages from './assets/smartphone.jpg?w=1000;1600';
import phone2Images from './assets/smartphone_call.jpg?w=1000;1600';
import toast from "react-hot-toast";

import media from "../../../utilities/media.js";
import {useEffect, useState} from "react";
import Sparkles from "../../atoms/Sparkles/Sparkles.jsx";

const PhoneStyled = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const PhoneContent = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1;

  ${media.lessThan('xl')} {
    transform: translateY(5%) scale(1.4);
  }
`;

const PhoneImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
  position: absolute;
  opacity: ${props => props.$visible ? 1 : 0};
  transition: opacity 0.5s ease-in-out;
`;

const PhoneLink = styled.div`
  position: absolute;
  cursor: pointer;
  z-index: 2;
`;

const Smartphone = ({onSolve, levelSolved = false}) => {
  const [solved, setSolved] = useState(levelSolved);

  useEffect(() => {
      if (solved) {
        setTimeout(() => {
          onSolve();
        }, 1500)
      }
    },
    [solved]
  );

  return (
    <PhoneStyled>
      <PhoneContent>
        {solved ? (
          <Sparkles/>
        ) : null}
        <PhoneImg src={phoneImages[1]} $visible={!solved}/>
        <PhoneImg src={phone2Images[1]} $visible={solved}/>
        {/*<PhoneLink style={{*/}
        {/*  top: '27.5%',*/}
        {/*  left: '35%',*/}
        {/*  width: '31%',*/}
        {/*  height: '12.5%'*/}
        {/*}} onClick={() => {*/}
        {/*  toast('Das ist nicht die richtige Nummer!');*/}
        {/*}}/>*/}
        {/*<PhoneLink style={{*/}
        {/*  top: '40%',*/}
        {/*  left: '35%',*/}
        {/*  width: '31%',*/}
        {/*  height: '12.5%'*/}
        {/*}} onClick={() => {*/}
        {/*  toast('Das ist nicht die richtige Nummer!');*/}
        {/*}}/>*/}
        <PhoneLink style={{
          top: '52.5%',
          left: '35%',
          width: '31%',
          height: '12.5%'
        }} onClick={() => {
          setSolved(true);
        }}/>
        {/*<PhoneLink style={{*/}
        {/*  top: '65%',*/}
        {/*  left: '35%',*/}
        {/*  width: '31%',*/}
        {/*  height: '12.5%'*/}
        {/*}} onClick={() => {*/}
        {/*  toast('Das ist nicht die richtige Nummer!');*/}
        {/*}}/>*/}
      </PhoneContent>
    </PhoneStyled>
  )
}

export default Smartphone;
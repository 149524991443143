import Stage from "../../base/Stage/Stage.jsx";
import Marker from "../../../Marker.jsx";
import Details from "../../base/Details/Details.jsx";
import AnimatedRoute from "../../base/AnimatedRoute/AnimatedRoute.jsx";
import {useContext, useEffect, useState} from "react";
import {useLocation} from "wouter";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";

import introAudio from './audio/level6_intro.mp3';
import outroAudio from './audio/level6_outro.mp3';
import styled from "styled-components";
import {headline2} from "../../../styles/typography.js";
import PictureWall from "./PictureWall.jsx";

const Level6 = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [solved, setSolved] = useState(false);
  const [location, setLocation] = useLocation();
  const {setLevel, saveLevelTime} = useContext(AppContext);

  useEffect(() => {
    if (showIntro && location === '/') {
      setTimeout(() => {
        setLocation('/intro');
        setShowIntro(false);
      }, 1000);
    }

  }, [showIntro])

  useEffect(() => {
    saveLevelTime(1);
  }, [])

  return (
    <>
      <Stage lightsOn>
        <Marker
          top={50}
          left={27}
          href={'/intro'}
        />
        <Marker
          top={40}
          left={70}
          message="Rudolphine kann Dir hier leider auch nicht helfen"
        />
        <Marker
          top={50}
          left={60}
          message="Auf dem Mischpult ist nichts."
        />
        <Marker
          top={40}
          left={80}
          message="Ohh das ist wirklich ein schöner Weihnachtsbaum."
        />
        <Marker
          top={40}
          left={45}
          href={'/details/1'}
        />
        <Marker
          top={80}
          left={63}
          message="Eine Wunschliste von einem Kind, wie süß. Gut, dass noch alles lesbar ist."
        />
        <Marker
          top={82}
          left={25}
          message="Das ist nur eine Grußkarte."
        />
        <Marker
          top={55}
          left={13}
          message="Das Keyboard hilft Dir jetzt auch nicht weiter."
        />
      </Stage>
      <AnimatedRoute path={`/intro`}>
        <Details
          title="Kapitel 6: Wer ist es?"
          interstitial
          audio={introAudio}
          text={`<p>
            Weihnachtsmann aus dem Radio:<br/>
            „Vielen Dank für deine bisherige Unterstützung! Ich glaube Du 
            bist auf einem sehr guten Weg. Die nächste Einstellung auf dem 
            Mischpult habe ich für alle Fälle einem Freund mitgeteilt.
            </p>
            <p> 
            Aber ich bin vom Blitz immer noch so durcheinander, dass ich nicht 
            mehr weiß, welcher meiner Freunde die Lösung kennt. Bitte hilf mir!“
            </p>`}
        />
      </AnimatedRoute>
      <AnimatedRoute path={`/details/1`}>
        <Details
          title="Die Freunde des Weihnachtsmanns"
          interstitial={solved}
          audio={solved ? outroAudio : null}
          text={solved ? `<p>
Weihnachtsmann aus dem Radio:<br/>
„Ja, natürlich – das ist er! Wie konnte ich das nur vergessen?"
</p>` : null}
          onContinue={() => {
            setLevel(7);
            setLocation('/');
          }}
        >
          <PictureWall onSolve={() => {
            setSolved(true)
            saveLevelTime(2);
          }}/>
        </Details>
      </AnimatedRoute>#
    </>
  )
}

export default Level6;
import Stage from "../../base/Stage/Stage.jsx";
import Marker from "../../../Marker.jsx";
import Details from "../../base/Details/Details.jsx";
import AnimatedRoute from "../../base/AnimatedRoute/AnimatedRoute.jsx";
import StartScreen from "../StartScreen/StartScreen.jsx";
import {useContext, useEffect, useState} from "react";
import Radio from "./Radio.jsx";
import {useLocation} from "wouter";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";

import introAudio from "./audio/level1_intro.mp3";
import santaAudio from "./audio/level1_santa.mp3";

const Level1 = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [solvedRadio, setSolvedRadio] = useState(false);
  const [location, setLocation] = useLocation();
  const {setLevel, saveLevelTime} = useContext(AppContext);

  useEffect(() => {
    if (showIntro && location === '/') {
      setTimeout(() => {
        setLocation('/intro');
        setShowIntro(false);
      }, 1000);
    }
  }, [showIntro])

  useEffect(() => {
    saveLevelTime(1);
  }, [])

  return (
    <>
      <Stage>
        <Marker
          top={50}
          left={27}
          href={'/details/1'}
        />
        <Marker
          top={40}
          left={47}
          message="Das sieht aus wie Fotos, die machen sicher kein Geräusch."
        />
        <Marker
          top={40}
          left={38}
          message="Die Tür ist verschlossen, keine Chance hier heraus zu kommen."
        />
        <Marker
          top={40}
          left={70}
          message="Eine dunkle Kabine, hier kommen die Geräusche nicht her."
        />
        <Marker
          top={40}
          left={80}
          message="Im Weihnachtsbaum ist nichts."
        />
        <Marker
          top={65}
          left={40}
          message="Das ist nur ein Haufen Zettel"
        />
        <Marker
          top={55}
          left={13}
          message="Das Keyboard hat keinen Strom."
        />
      </Stage>
      <AnimatedRoute path={`/details/1`}>
        <Details
          title="Das Radio"
          audio={solvedRadio ? santaAudio : null}
          interstitial={solvedRadio}
          onContinue={() => {
            setLevel(2);
            setLocation('/');
          }}
          text={!solvedRadio
            ? null
            : `<p>Weihnachtsmann aus dem Radio:<br/>
            "Hallo? Haaaallo! Hört mich jemand? Hier spricht der Weihnachtsmann ... 
            ja, glaub mir oder nicht, aber ich bin's wirklich! Ich war gerade dabei, 
            einen neuen Weihnachtshit für meine große Weihnachts-Audio-Show zu produzieren.
            </p>
            <p>Plötzlich ein Riesenknall! Ich glaube ich wurde vom Blitz getroffen, und 
            irgendwie ... keine Ahnung, wie ich es erklären soll ... in eine andere Dimension 
            katapultiert, die nur aus Radio-Wellen zu bestehen scheint. Außerdem haben alle 
            meine Geräte den Geist aufgegeben. Vor allem mein Mischpult, an dem ich den Weihnachthit 
            abmischen wollte.</p>
            <p> Ich weiß nicht, was ich jetzt machen soll? Kein neuer Weihnachts-Hit, keine weltberühmte 
            Weihnachtsshow – das wäre eine Katastrophe!!! Bitte, wer auch immer diese Nachricht hört, 
            helft mir dabei, das Tonstudio wieder in Gang zu bringen und mich somit hoffentlich zurück 
            in die echte Welt zu holen.</p> 
            <p>Vielleicht gelingt es, wenn die Regler wieder in die Ausgangsposition gesetzt werden. 
            Ich bleibe natürlich an Deiner Seite und versuche, zu helfen. Weihnachten muss unbedingt gerettet 
            werden."</p>
</p>`}
          mobileScale={2}
          mobileTop="-20%"
          mobileLeft="20%"
          tip={`Probier doch mal, was Du am Radio einstellen kannst!`}
          tipId={1}
        >
          <Radio onSolve={() => {
            saveLevelTime(2);
            setSolvedRadio(true);
          }}/>
        </Details>
      </AnimatedRoute>
      <AnimatedRoute path={`/intro`}>
        <Details
          title="Kapitel 1: Gefangen - was nun?"
          interstitial
          audio={introAudio}
          text={`<p>
            Durch einen starken Luftzug knallt die Tür hinter Dir plötzlich ins Schloss und 
            lässt sich einfach nicht mehr öffnen. Jetzt bist du auch noch gefangen – was für 
            ein Mist!!! Im Tonstudio herrscht gespenstische Stille ... halt, nein! Es ist dann 
            doch etwas zu hören. Ein leises Rauschen und Knistern. Wo kommt das her?
            </p>`}
        />
      </AnimatedRoute>
    </>
  )
}

export default Level1;
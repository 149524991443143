import {useContext, useState} from "react";
import FormField from "../../atoms/FormField/FormField.jsx";
import Button from "../../atoms/Button/Button.jsx";
import styled from "styled-components";
import media from "../../../utilities/media.js";
import {headline2, smalltext} from "../../../styles/typography.js";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";
import toast from "react-hot-toast";
import {useLocation} from "wouter";

export const base_url = import.meta.env.DEV ? "http://rms-escape-game.test" : "";

const FormStyled = styled.form`
  background-color: ${props => props.theme.colors.white};
  padding: 10px;
  max-height: 80vh;
  overflow-y: auto;
`;

const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`;

const FormHalf = styled.div`
  width: 100%;
  padding: 0 10px;

  ${media.moreThan('md')} {
    width: 50%;
  }
`;

const FormButton = styled(Button)`
  background-color: ${props => props.theme.colors.primary};
  border: none;
  color: ${props => props.theme.colors.white};
`;

const SmallText = styled.div`
  ${smalltext};
  padding: 10px 0;
  
  p {
    margin-bottom: 0.5em;
  }
`;

const ButtonRow = styled.div`
  display: flex;
`;

const Headline= styled.h2`
  ${headline2};
  margin-bottom: 10px;
`;

const RegistrationForm = () => {
  const [salutation, setSalutation] = useState(''); // Mr. / Mrs.
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [nickname, setNickname] = useState('');
  const [company, setCompany] = useState('');
  const [zip, setZip] = useState('');
  const [marketing, setMarketing] = useState(true);
  const [, setLocation] = useLocation();
  const { uid, setLevel } = useContext(AppContext);
  const [sending, setSending] = useState(false);

  const sendToBackend = () => {
    const form = new FormData();
    form.append('Salutation', salutation);
    form.append('FirstName', firstName);
    form.append('LastName', lastName);
    form.append('Email', email);
    form.append('Nickname', nickname);
    form.append('Company', company);
    form.append('Zip', zip);
    form.append('Uid', uid);
    if(marketing)
      form.append('Marketing', '1');

    form.append("action_submit", "Submit");

    fetch(`${base_url}/api/EntryForm`, {
      method: 'POST',
      body: form,
    })
      .then(response => {
        toast('Deine Registrierung beim RMS X-Mas Escape Game war erfolgreich.');
        setLocation('/');
        setLevel(4);
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!sending) {
      setSending(true);
      sendToBackend();
    }
  }

  return (
    <FormStyled onSubmit={handleSubmit}>
      <FormGroup>
        <FormHalf>
          <Headline>Registrierung</Headline>
          <FormField
            label="Nickname (für den Highscore)"
            type="text"
            name="nickname"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            description="Optional - nur, wenn Du im Highscore erscheinen möchtest."
          />
        </FormHalf>
        <FormHalf>
        </FormHalf>
      </FormGroup>
      <FormGroup>
        <FormHalf>
          <FormField
            label="Anrede"
            type="select"
            name="salutation"
            value={salutation}
            onChange={(e) => setSalutation(e.target.value)}
            required
            options={[
              {value: "", title: ''},
              {value: "Mr.", title: 'Herr'},
              {value: "Mrs.", title: 'Frau'},
            ]}
          />
          <FormField
            label="Vorname"
            type="text"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <FormField
            label="Nachname"
            type="text"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <FormField
            label="E-Mail"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </FormHalf>
        <FormHalf>
          <FormField
            label="Unternehmen"
            type="text"
            name="company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            required
          />
          <FormField
            label="PLZ"
            type="text"
            name="zip"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
          />
          <FormField
            label="Ja, ich bin damit einverstanden, dass RMS Radio Marketing Service GmbH und Co. KG mich künftig über Leistungen, Produkte und Aktionen von RMS informiert."
            type="checkbox"
            name="marketing"
            value={marketing}
            onChange={(e) => setMarketing(!marketing)}
          />
        </FormHalf>
      </FormGroup>
      <SmallText>
        <p>*Pflichtfeld</p>
        <p>Mit Absenden des Formulars akzeptierst du die <a href="/pages/gewinnspiel" target="_blank">Teilnahmebedingungen</a>.</p>
        <p>Informationen zum Datenschutz: Wir verarbeiten Deine Daten zur Durchführung der RMS Escape
Games 2023 und des Gewinnspieles (Art. 6 (1)(b) DSGVO). Hierzu senden wir dir entsprechende
Benachrichtigungen und Hinweise per E-Mail. Eine Teilnahme an den Escape Games und dem
Gewinnspiel ist ohne Bereitstellung der Daten nicht möglich. Deine Teilnahme kannst du aber
jederzeit beenden. Wir speichern deine Daten für längstens sechs Monate.
          </p>
<p>Nur sofern du außerdem eingewilligt hast (Art. 6(1)(a) DSGVO), verwenden wir deine Daten um
dich werblich zu kontaktieren und dir unseren Newsletter zuzusenden. Deine Einwilligung kannst
Du jederzeit mit Wirkung für die Zukunft widerrufen. Weitere Informationen zum Datenschutz
  findest Du auch in der <a href="https://rms.de/datenschutz" target="_blank">Datenschutzerklärung</a>
        </p>
      </SmallText>
      <ButtonRow>
        <FormButton type="submit">Absenden</FormButton>
      </ButtonRow>
    </FormStyled>
  )
}

export default RegistrationForm;
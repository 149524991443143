import Stage from "../../base/Stage/Stage.jsx";
import Marker from "../../../Marker.jsx";
import Details from "../../base/Details/Details.jsx";
import AnimatedRoute from "../../base/AnimatedRoute/AnimatedRoute.jsx";
import {useContext, useEffect, useState} from "react";
import {useLocation} from "wouter";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";

import labelImages from './assets/etikett.jpg?w=1000;1600';
import introAudio from './audio/level9_intro.mp3';
import outroAudio from './audio/level9_outro.mp3';
import Mischpult1 from "../Level3/Mischpult1.jsx";

const Level9 = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [solved, setSolved] = useState(false);
  const [location, setLocation] = useLocation();
  const {setLevel, saveLevelTime} = useContext(AppContext);

  useEffect(() => {
    if (showIntro && location === '/') {
      setTimeout(() => {
        setLocation('/intro');
        setShowIntro(false);
      }, 1000);
    }

  }, [showIntro])

  useEffect(() => {
    saveLevelTime(1);
  }, [])

  return (
    <>
      <Stage lightsOn santa={solved}>
        <Marker
          top={50}
          left={27}
          href={'/intro'}
        />
        <Marker
          top={40}
          left={70}
          message="Rudolphine weiß auch nicht weiter."
        />
        <Marker
          top={50}
          left={60}
          href={`/details/3`}
        />
        <Marker
          top={53}
          left={66}
          message="Hier steht nichts, was Dir hilft."
        />
        <Marker
          top={40}
          left={80}
          message="Ohh das ist wirklich ein schöner Weihnachtsbaum."
        />
        <Marker
          top={58}
          left={69.5}
          href={'/details/1'}
        />
        <Marker
          top={55}
          left={13}
          message="Hier ist leider kein Hinweis."
        />
        <Marker
          top={80}
          left={63}
          message="Das ist nur Papier."
        />
        <Marker
          top={82}
          left={38}
          message="Das hilft Dir nicht weiter."
        />
      </Stage>
      <AnimatedRoute path={`/intro`}>
        <Details
          title="Kapitel 9: Das magische Mischpult"
          interstitial
          audio={introAudio}
          text={`<p>
            Weihnachtsmann aus dem Radio:<br/> 
            „Moment mal.. irgendetwas stimmt nicht. Jetzt wo alle Regler richtig eingestellt sind, 
            müsste ich doch eigentlich wieder in real vor Dir stehen. Das verstehe ich nicht, 
            es fühlt sich alles so richtig an. Was kann jetzt noch falsch sein? Vielleicht hilft ja 
            ein kompletter Reset vom Mischpult? Irgendwo muss doch stehen, wie das funktioniert..."
            </p>`}
        />
      </AnimatedRoute>
      <AnimatedRoute path={`/details/1`}>
        <Details
          title="magisches-mischpult.de"
          images={labelImages}
        >
        </Details>
      </AnimatedRoute>
      <AnimatedRoute path={`/details/3`}>
        <Details
          title="Das magische Mischpult"
          tipId={4}
        >
          <Mischpult1
            active={5}
            start={[9, 6, 5, 7]}
            onSolved={() => {
              saveLevelTime(2);
              setSolved(true);
              setLocation('/outro');
            }}/>
        </Details>
      </AnimatedRoute>
      <AnimatedRoute path={`/outro`}>
        <Details
          title="Herzlichen Glückwunsch!"
          audio={outroAudio}
          right
          interstitial
          onContinue={() => {
            setLevel(10);
            setLocation('/');
          }}
          text={`<p>
            Weihnachtsmann in real:<br/>
            „Huch, wo bin ich denn nun gelandet? Ist das mein Tonstudio? Jaaaa, 
            ich fasse es nicht, wir haben's geschafft! Nein, Du hast es geschafft.
            </p>
            <p> 
            Ohne Deine Hilfe wäre ich wohl für immer in der Radio-Wellen Dimension verschollen. 
            Ich bin unendlich dankbar und erleichtert! Jetzt kann die größte Weihnachts-Audio-Show 
            der Welt doch noch starten. Was wäre Weihnachten ohne Audio? Du hast nicht nur mich, 
            sondern das ganze Weihnachtsfest gerettet! Vielen Dank dafür!“
            </p>`}
        />
      </AnimatedRoute>
    </>
  )
}

export default Level9;
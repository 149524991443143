import Draggable from "react-draggable";
import styled from "styled-components";
import {useState} from "react";

const PuzzlePartStyled = styled.div`
  z-index: 10;
  //border: 1px solid red;
  pointer-events: none;
`;

const PuzzlePartImg = styled.img`
  pointer-events: none;
  display: block;
  width: 100%;
`;

const Handler = styled.div`
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  //background: red;
  //opacity: 0.5;
  cursor: grab;
  pointer-events: auto;
`;

const PuzzlePart = (
  {
    width,
    height,
    src,
    step,
    style,
    handles = [{
      width: '80%',
      height: '80%',
      top: '10%',
      left: '10%',
    }],
    start = {
      top: 0,
      left: 0,
    },
    solution = {
      x: 0,
      y: 0,
    },
    setSolved,
  }
) => {
  const [draggablePosition, setDraggablePosition] = useState({x: 0, y: 0});

  return (
    <Draggable
      bounds="parent"
      handle=".handler"
      grid={[step, step]}
      position={draggablePosition}
      // onStart={(e, data) => {
      //   if (solved) {
      //     return false;
      //   }
      // }}
      onDrag={(e, data) => {
        const {x, y} = data;
        const valueX = Math.round(x / step + start.x);
        const valueY = Math.round(y / step + start.y);
        if (valueX === solution.x && valueY === solution.y) {
          setSolved(true);
        } else {
          setSolved(false);
        }
        setDraggablePosition({x, y});
      }}
    >
      <PuzzlePartStyled style={{
        position: 'absolute',
        width,
        // height,
        top: start.y * step,
        left: start.x * step,
        ...style,
      }}>
        <PuzzlePartImg src={src}/>
        {handles.map((handle, index) => <Handler key={index} className="handler" style={handle}/>)}
      </PuzzlePartStyled>
    </Draggable>

  )
}

export default PuzzlePart;
import styled from "styled-components";
import LampSvg from "./assets/lamp.svg?react";

const LampStyled = styled.div`
  width: 100%;

  svg {
    width: 100%;
    height: auto;

    .cls-1 {
      opacity: .04;
    }

    .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-22, .cls-23, .cls-24, .cls-25, .cls-26, .cls-27, .cls-28, .cls-29, .cls-30, .cls-31, .cls-32, .cls-33, .cls-34, .cls-35, .cls-36, .cls-37, .cls-38, .cls-39, .cls-40, .cls-41, .cls-42, .cls-43, .cls-44, .cls-45, .cls-46, .cls-47, .cls-48, .cls-49, .cls-50, .cls-51, .cls-52 {
      fill: ${props => props.$on ? '#8cc63f' : 'red'};
      transition: fill 0.3s ease-in-out;
    }

    .cls-2 {
      opacity: .06;
    }

    .cls-3 {
      opacity: .08;
    }

    .cls-4 {
      opacity: .12;
    }

    .cls-5 {
      opacity: .1;
    }

    .cls-7 {
      opacity: .16;
    }

    .cls-8 {
      opacity: .14;
    }

    .cls-9 {
      opacity: .24;
    }

    .cls-10 {
      opacity: .22;
    }

    .cls-11 {
      opacity: .2;
    }

    .cls-12 {
      opacity: .18;
    }

    .cls-13 {
      opacity: .02;
    }

    .cls-14 {
      opacity: 0;
    }

    .cls-15 {
      opacity: .69;
    }

    .cls-16 {
      opacity: .57;
    }

    .cls-17 {
      opacity: .59;
    }

    .cls-18 {
      opacity: .61;
    }

    .cls-19 {
      opacity: .63;
    }

    .cls-20 {
      opacity: .51;
    }

    .cls-21 {
      opacity: .53;
    }

    .cls-22 {
      opacity: .55;
    }

    .cls-23 {
      opacity: .49;
    }

    .cls-24 {
      opacity: .88;
    }

    .cls-25 {
      opacity: .9;
    }

    .cls-26 {
      opacity: .92;
    }

    .cls-27 {
      opacity: .82;
    }

    .cls-28 {
      opacity: .8;
    }

    .cls-29 {
      opacity: .84;
    }

    .cls-30 {
      opacity: .86;
    }

    .cls-31 {
      opacity: .76;
    }

    .cls-32 {
      opacity: .78;
    }

    .cls-33 {
      opacity: .71;
    }

    .cls-34 {
      opacity: .75;
    }

    .cls-35 {
      opacity: .73;
    }

    .cls-36 {
      opacity: .33;
    }

    .cls-37 {
      opacity: .29;
    }

    .cls-38 {
      opacity: .31;
    }

    .cls-39 {
      opacity: .25;
    }

    .cls-40 {
      opacity: .27;
    }

    .cls-41 {
      opacity: .45;
    }

    .cls-42 {
      opacity: .47;
    }

    .cls-43 {
      opacity: .41;
    }

    .cls-44 {
      opacity: .43;
    }

    .cls-45 {
      opacity: .39;
    }

    .cls-46 {
      opacity: .35;
    }

    .cls-47 {
      opacity: .37;
    }

    .cls-48 {
      opacity: .67;
    }

    .cls-49 {
      opacity: .65;
    }

    .cls-50 {
      opacity: .94;
    }

    .cls-51 {
      opacity: .96;
    }

    .cls-52 {
      opacity: .98;
    }
  }
`;

const Lamp = (
  {
    on = false,
    onClick = () => {}
  }
) => {
  return (
    <LampStyled $on={on} onClick={onClick}>
      <LampSvg/>
    </LampStyled>
  )
}

export default Lamp;
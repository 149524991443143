import Stage from "../../base/Stage/Stage.jsx";
import Marker from "../../../Marker.jsx";
import Details from "../../base/Details/Details.jsx";
import AnimatedRoute from "../../base/AnimatedRoute/AnimatedRoute.jsx";
import {useContext, useEffect, useState} from "react";
import {useLocation} from "wouter";

const LevelLocked = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [location, setLocation] = useLocation();

  useEffect(() => {
    if (showIntro && location === '/') {
      setTimeout(() => {
        setLocation('/intro');
        setShowIntro(false);
      }, 1000);
    }

  }, [showIntro])

  return (
    <>
      <Stage lightsOn>
        <Marker
          top={50}
          left={27}
          href={'/intro'}
        />
        <Marker
          top={40}
          left={70}
          message="Das ist Rudolphine."
        />
        <Marker
          top={50}
          left={60}
          message="Das ist das magische Mischpult."
        />
        <Marker
          top={40}
          left={80}
          message="Ohh das ist wirklich ein schöner Weihnachtsbaum."
        />
        <Marker
          top={40}
          left={45}
          message="Das sind Bilder."
        />
        <Marker
          top={55}
          left={13}
          message="Das ist ein Keyboard."
        />
      </Stage>
      <AnimatedRoute path={`/intro`}>
        <Details
          title="Tagesabschluss"
          interstitial
          buttonText="Weiter umsehen"
          text={`<p>
            Herzlichen Glückwunsch! Du hast die aktuellen Kapitel des RMS X-Mas Escape Game erfolgreich gemeistert. 
            Doch die Reise ist noch nicht zu Ende... Das Weihnachtsfest hängt immer noch am seidenen Faden, 
            und der Weihnachtsmann zählt auf Dich! Bist Du bereit? Nächsten Montag geht es weiter. Freu dich drauf!
            </p>`}
        />
      </AnimatedRoute>
    </>
  )
}

export default LevelLocked;
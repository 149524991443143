import {useRoute} from "wouter";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import React, {useRef} from "react";

const AnimatedRoute = ({children, path}) => {
  // `match` is boolean
  const [match, params] = useRoute(path);
  const nodeRef = useRef(null);

  const childList = React.Children.toArray(children);

  return (
    <CSSTransition
      in={match}
      timeout={1500}
      classNames="page"
      nodeRef={nodeRef}
      unmountOnExit
    >
      <div className="page" ref={nodeRef}>
        {childList.map((child, index) => React.cloneElement(child, { visible: match }))}
      </div>
    </CSSTransition>
  );
};

export default AnimatedRoute;
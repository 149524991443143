import styled from "styled-components";
import kastenImages from "./assets/stromkasten_bg.jpg?w=1000;1600";
import switch_src from "./assets/switch.svg";
import media from "../../../utilities/media.js";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import Sparkles from "../../atoms/Sparkles/Sparkles.jsx";

const KastenStyled = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const KastenContent = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${media.lessThan('lg')} {
    transform: scale(1.2);
    top: -30%;
    left: -15%;
  }
`;

const KastenImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const Switch = styled.div`
  position: absolute;
  width: 7.5%;
  height: 6%;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    left: 10%;
    bottom: 0;
    width: 80%;
    height: 30%;
    background-image: url(${switch_src});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: ${({$active}) => $active ? 'translateY(-200%)' : 'translateY(0)'};
    transition: transform 0.3s ease-in-out;
  }
`;

const Stromkasten = ({onSolve}) => {
  const [solved, setSolved] = useState(false);
  const [switches, setSwitches] = useState({
    A: false,
    B: false,
    1: false,
    2: false,
    3: false,
    4: false,
  });
  const [switched, setSwitched] = useState([]);
  const correct = ['B', 3, 2, 'A', 4, 1];

  const switchOn = (switchName) => {
    const newSwitched = {...switches};
    newSwitched[switchName] = true;
    setSwitches(newSwitched);
    setSwitched([...switched, switchName]);
  }

  useEffect(() => {
    const correctFirst = correct.slice(0, switched.length);
    if (switched.length && JSON.stringify(switched) !== JSON.stringify(correctFirst)) {
      toast('Das ist leider falsch. Versuche es noch einmal.');
      setTimeout(() => {
        setSwitches({
          A: false,
          B: false,
          1: false,
          2: false,
          3: false,
          4: false,
        });
        setSwitched([]);
      }, 1000);
    } else {
      if (switched.length === correct.length) {
        setSolved(true);
        setTimeout(() => {
          onSolve();
        }, 3000);
      }
    }
  }, [switched]);

  return (
    <KastenStyled>
      <KastenContent>
        {solved ? (
          <Sparkles />
        ) : null}
          <KastenImg src={kastenImages[1]}/>

        <Switch
          style={{left: '47%', top: '36%'}}
          $active={switches['A']}
          onClick={() => switchOn('A')}
        />
        <Switch
          style={{left: '71.25%', top: '36%'}}
          $active={switches['B']}
          onClick={() => switchOn('B')}
        />
        <Switch
          style={{left: '42.45%', top: '68%'}}
          $active={switches[1]}
          onClick={() => switchOn(1)}
        />
        <Switch
          style={{left: '52.75%', top: '68%'}}
          $active={switches[2]}
          onClick={() => switchOn(2)}
        />
        <Switch
          style={{left: '63%', top: '68%'}}
          $active={switches[3]}
          onClick={() => switchOn(3)}
        />
        <Switch
          style={{left: '73.3%', top: '68%'}}
          $active={switches[4]}
          onClick={() => switchOn(4)}
        />
      </KastenContent>
    </KastenStyled>
  )
}

export default Stromkasten;
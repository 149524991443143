import styled from "styled-components";
import toast from "react-hot-toast";

import mischpult_blanko from "../assets/05_mischpult_blanko.svg";
import mischpult_button from "./assets/mischpult_button.svg";
import media from "../../../utilities/media.js";
import {useState} from "react";
import Sparkles from "../../atoms/Sparkles/Sparkles.jsx";
import Regler from "./Regler.jsx";
import {inputtext} from "../../../styles/typography.js";

const MischpultStyled = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const MischpultContent = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

    // ${media.lessThan('lg')} {
  //   transform: scale(1.2);
  //   top: -30%;
  //   left: -15%;
  // }
`;

const MischpultImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const ResetButton = styled.img`
  position: absolute;
  bottom: 4%;
  right: 4%;
  width: 10%;
  cursor: pointer;
`;

const ResetFormStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.backgroundTrans};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
`;

const ResetFormContent = styled.form`
  max-width: 300px;
`;

const ResetFormInput = styled.input`
  ${inputtext};
  text-align: center;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace
  padding: 10px 20px;
  font-size: 3rem;
  width: 100%;
`;

const BtnPad = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Btn = styled.button`
  ${inputtext};
  font-size: 1.5rem;
  width: calc(100% / 3);
  aspect-ratio: 3/2;
  
  svg {
    width: 50%;
    height: auto;
  }
`;

const ResetForm = ({ onReset }) => {
  const [code, setCode] = useState('');

  return (
    <ResetFormStyled>
      <ResetFormContent onSubmit={(e) => {
        e.preventDefault();
        if(code === '10165') {
          onReset();
        } else {
          setCode('');
          toast('Falscher Code');
        }
      }}>
        <ResetFormInput type="text" placeholder="Code" value={code} onChange={e => setCode(e.target.value)}/>
        <BtnPad>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((n) => (
            <Btn
              key={n}
              type="button"
              onClick={() => {
                setCode(code + n);
              }}
            >
              {n}
            </Btn>
          ))}
          <Btn
            key={'del'}
            type="button"
            onClick={() => {
              setCode('');
            }}
          >
            <svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200">
             <path d="m675 539.64 82.324-82.32c9.7617-9.7656 25.59-9.7656 35.352 0 9.7656 9.7617 9.7656 25.59 0 35.352l-82.32 82.324 82.32 82.324c9.7656 9.7617 9.7656 25.59 0 35.352-9.7617 9.7656-25.59 9.7656-35.352 0l-82.324-82.32-82.324 82.32c-9.7617 9.7656-25.59 9.7656-35.352 0-9.7656-9.7617-9.7656-25.59 0-35.352l82.32-82.324-82.32-82.324c-9.7656-9.7617-9.7656-25.59 0-35.352 9.7617-9.7656 25.59-9.7656 35.352 0zm350 360.36h-650c-6.6289 0-12.988-2.6328-17.676-7.3242l-300-300c-9.7656-9.7617-9.7656-25.59 0-35.352l300-300c4.6875-4.6914 11.047-7.3242 17.676-7.3242h650c13.809 0 25 11.191 25 25v600c0 13.809-11.191 25-25 25zm-25-50v-550h-614.64l-275 275 275 275z"/>
            </svg>
          </Btn>
          <Btn
            key={'enter'}
            type="submit"
          >
            <svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200">
             <g>
              <path d="m453.84 389.04c-32.16 34.078-64.078 68.398-96.238 102.48-50.879 54.238-101.76 108.48-152.64 162.72-11.762 12.719-23.762 25.199-35.52 37.922-14.16 14.879-4.3203 41.039 17.039 41.039h82.32 197.76 237.84 206.88c33.359 0 67.199 1.4414 100.56 0h1.4414c12.961 0 24-11.039 24-24v-173.04-274.56-63.359c0-30.961-48-30.961-48 0v173.04 274.56 63.359l24-24h-82.32-197.76-237.84-206.88-68.16c-10.559 0-21.84-0.96094-32.398 0h-1.4414c5.7617 13.68 11.281 27.359 17.039 41.039 32.16-34.078 64.078-68.398 96.238-102.48 50.879-54.238 101.76-108.48 152.64-162.72 11.762-12.719 23.762-25.199 35.52-37.922 20.883-22.797-12.957-56.879-34.078-34.078z"/>
              <path d="m498.96 985.44c-33.359-33.121-66.719-66.238-100.08-99.359-52.801-52.559-105.84-105.12-158.64-157.68-12.238-12.238-24.48-24.238-36.719-36.48-21.84-21.84-55.922 12.238-33.84 33.84 33.359 33.121 66.719 66.238 100.08 99.359 52.801 52.559 105.84 105.12 158.64 157.68 12.238 12.238 24.48 24.238 36.719 36.48 21.84 21.84 55.68-12 33.84-33.84z"/>
             </g>
            </svg>
          </Btn>
        </BtnPad>
      </ResetFormContent>
    </ResetFormStyled>
  );
};


const Mischpult1 = (
  {
    active=1,
    onSolved,
    start=[0, 0, 0, 0]
  }) => {
  const [solved, setSolved] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);

  const solve = () => {
    setSolved(true);
    setTimeout(() => {
      onSolved();
    }, 3000);
  }

  return (
    <MischpultStyled>
      <MischpultContent>
        {solved ? <Sparkles/> : null}
        <MischpultImg src={mischpult_blanko} alt="Mischpult" />
        <Regler active={active === 1} start={start[0]} correct={10} onSolve={solve}/>
        <Regler active={active === 2} start={start[1]} correct={6} left="30.2%" onSolve={solve}/>
        <Regler active={active === 3} start={start[2]} correct={5} left="52.8%" onSolve={solve}/>
        <Regler active={active === 4} start={start[3]} correct={8} left="75.5%"  onSolve={solve}/>
        {active === 5 ? <ResetButton src={mischpult_button} alt="Reset" onClick={() => {
          setShowResetForm(true);
        }}/> : null}
        {showResetForm ? <ResetForm onReset={() => solve()}/> : null}
      </MischpultContent>
    </MischpultStyled>
  )
}

export default Mischpult1;
import Stage from "../../base/Stage/Stage.jsx";
import Marker from "../../../Marker.jsx";
import Details from "../../base/Details/Details.jsx";
import AnimatedRoute from "../../base/AnimatedRoute/AnimatedRoute.jsx";
import {useContext, useEffect, useState} from "react";
import {useLocation} from "wouter";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";

import geheimfachImages from './assets/keyboard_geheim.jpg?w=1000;1600';
import introAudio from './audio/level5_intro.mp3';
import Keyboard from "./Keyboard.jsx";
import Mischpult1 from "../Level3/Mischpult1.jsx";

const Level5 = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [solved, setSolved] = useState(false);
  const [location, setLocation] = useLocation();
  const {setLevel, saveLevelTime} = useContext(AppContext);

  useEffect(() => {
    if (showIntro && location === '/') {
      setTimeout(() => {
        setLocation('/intro');
        setShowIntro(false);
      }, 1000);
    }

  }, [showIntro])

  useEffect(() => {
    saveLevelTime(1);
  }, [])

  return (
    <>
      <Stage lightsOn>
        <Marker
          top={50}
          left={27}
          href={'/intro'}
        />
        <Marker
          top={40}
          left={70}
          message="Rudolphine kann Dir jetzt auch nicht helfen"
        />
        <Marker
          top={50}
          left={60}
          message={solved ? null : "Du brauchst eine Zahl, um sie auf dem Mischpult einzustellen."}
          href={solved ? `/details/3` : null}
        />
        <Marker
          top={40}
          left={80}
          message="Ohh das ist wirklich ein schöner Weihnachtsbaum."
        />
        <Marker
          top={40}
          left={45}
          message="Das sind nur Bilder."
        />
        <Marker
          top={55}
          left={13}
          href={solved ? '/details/2' : '/details/1'}
        />
        <Marker
          top={82}
          left={38}
          message="Auf der Gitarre kann leider nichts mehr gespielt werden."
        />
      </Stage>
      <AnimatedRoute path={`/intro`}>
        <Details
          title="Kapitel 5: Alle Jahre wieder..."
          interstitial
          audio={introAudio}
          text={`<p>
            Weihnachtsmann aus dem Radio:
            „Ja genau. Das war das Lied. Super, dass Du es zusammen gesetzt hast. Aber kannst du die Melodie auch spielen?"
            </p>`}
        />
      </AnimatedRoute>
      <AnimatedRoute path={`/details/1`}>
        <Details
          title="Das Keyboard"
        >
          <Keyboard onSolve={() => {
            setSolved(true)
            setLocation('/details/2')
          }}/>
        </Details>
      </AnimatedRoute>
      {solved ? (
        <AnimatedRoute path={`/details/2`}>
          <Details
            title="Die Schublade"
            images={geheimfachImages}
            tip="Ah, das sind doch wieder die vier Regler vom Mischpult. Wie stelle ich das noch einmal ein?"
            tipId={5}
          />
        </AnimatedRoute>
      ) : null}
      <AnimatedRoute path={`/details/3`}>
        <Details
          title="Das magische Mischpult"
        >
          <Mischpult1
          active={2}
          start={[9, 0, 0, 0]}
            onSolved={() => {
            saveLevelTime(2);
            setSolved(true);
            setLevel(6);
            setLocation('/');
          }}/>
        </Details>
      </AnimatedRoute>
    </>
  )
}

export default Level5;
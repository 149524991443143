import styled from "styled-components";
import {inputtext, smalltext} from "../../../styles/typography.js";
import media from "../../../utilities/media.js";

const FormFieldStyled = styled.div`
  width: 100%;
  padding-bottom: 10px;
`;

const FormLabel = styled.label`
  display: block;
  ${inputtext};
`;

const FormInput = styled.input`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.black};
  border-radius: 3px;
  height: 24px;
  ${inputtext};
`;

const FormSelect = styled.select`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.black};
  border-radius: 3px;
  width: 100%;
  height: 24px;
  ${inputtext};
`;

const CheckboxFormFieldStyled = styled(FormFieldStyled)`
  display: flex;
  align-items: flex-start;

  ${media.moreThan('lg')} {
    padding-top: 20px;
  }
`;

const CheckboxFormInput = styled(FormInput)`
  height: auto;
  width: auto;
  flex: 0 0 auto;
`;

const CheckboxLabel = styled(FormLabel)`
  font-size: 14px;
`;

const SmallText = styled.div`
  ${smalltext};
  padding-top: 5px;
`;

const FormField = ({label, type, name, value, onChange, required, options, description}) => {

  let formInput = <FormInput type={type} name={name} value={value} onChange={onChange} required={required}/>;

  if (type === "select") {
    formInput = (
      <FormSelect name={name} value={value} onChange={onChange} required={required}>
        {options.map((option, index) => <option key={index} value={option.value}>{option.title}</option>)}
      </FormSelect>
    )
  }

  if (type === "checkbox") {
    return (
      <CheckboxFormFieldStyled>
        <CheckboxFormInput type={type} name={name} id={name} checked={value} onChange={onChange} required={required}/>
        <CheckboxLabel htmlFor={name}>{label}{required ? '*' : null}</CheckboxLabel>
      </CheckboxFormFieldStyled>
    );
  }

  return (
    <FormFieldStyled>
      <FormLabel>{label}{required ? '*' : null}:</FormLabel>
      {formInput}
      { description ? (
        <SmallText>
          <p>
            {description}
          </p>
        </SmallText>
      ) : null}

    </FormFieldStyled>
  )
}

export default FormField;
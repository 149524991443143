import styled from "styled-components";
import keyboardImages from './assets/keyboard.jpg?w=1000;1600';
import {Piano, KeyboardShortcuts, MidiNumbers} from 'react-piano';
import 'react-piano/dist/styles.css';
import toast from "react-hot-toast";

import media from "../../../utilities/media.js";
import {useEffect, useState, useRef} from "react";
import Sparkles from "../../atoms/Sparkles/Sparkles.jsx";
import {SplendidGrandPiano} from "smplr";
import {buttontext} from "../../../styles/typography.js";

const KeyboardStyled = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const KeyboardContent = styled.div`
  position: relative;
  width: 100%;
  
`;

const KeyboardImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const PianoContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: +100;
  width: 100%;
  height: 35%;
  overflow: hidden;
  background-color: ${props => props.theme.colors.white};
  
  .ReactPiano__Keyboard {
    width: 100%;
    height: 100%;
  }
  
  .ReactPiano__NoteLabel {
    color: ${props => props.theme.colors.primary};
    text-transform: lowercase;
    ${buttontext};
    padding-bottom: 10px;
  }
  
  .ReactPiano__Key--active.ReactPiano__Key--natural {
    background: ${props => props.theme.colors.primary};
    
    .ReactPiano__NoteLabel {
      color: ${props => props.theme.colors.white};
    }
  }
`;

const noteRange = {
  first: MidiNumbers.fromNote('c3'),
  last: MidiNumbers.fromNote('e4'),
};
const keyboardShortcuts = KeyboardShortcuts.create({
  firstNote: noteRange.first,
  lastNote: noteRange.last,
  keyboardConfig: [{
    natural: 'c',
    flat: '',
    sharp: ''
  }, {
    natural: 'd',
    flat: '',
    sharp: ''
  }, {
    natural: 'e',
    flat: '',
    sharp: ''
  }, {
    natural: 'f',
    flat: '',
    sharp: ''
  }, {
    natural: 'g',
    flat: '',
    sharp: ''
  }, {
    natural: 'a',
    flat: '',
    sharp: ''
  }, {
    natural: 'h',
    flat: '',
    sharp: ''
  }],
});

const midiMap = {
  48: 'c',
  50: 'd',
  52: 'e',
  53: 'f',
  55: 'g',
  57: 'a',
  59: 'b',
  60: 'c',
  62: 'd',
  64: 'e',
  65: 'f',
};

const Keyboard = ({onSolve}) => {
  const [solved, setSolved] = useState(false);
  // const [playedNotes, setPlayedNotes] = useState([]);
  const context = new AudioContext();
  const piano = new SplendidGrandPiano(context);

  const correct = ['g', 'a', 'g', 'f', 'e', 'd', 'c', 'd', 'e', 'f', 'e', 'd'];

  const playedNotes = useRef([]);

  // useEffect(() => {
  //   if(playedNotes.length === 0) {
  //     return;
  //   }
  //   toast(playedNotes.join(', '));
  // }, [playedNotes]);

  return (
    <KeyboardStyled>
      <KeyboardContent>
        {solved ? (
          <Sparkles/>
        ) : null}
        <KeyboardImg src={keyboardImages[1]}/>
        <PianoContainer>
          <Piano
            noteRange={noteRange}
            playNote={(midiNumber) => {
              piano.start({note: midiNumber});
              const note = midiNumber in midiMap ? midiMap[midiNumber] : null;
              if (note === null) {
                // Reset
                return;
              }
              // setPlayedNotes([...playedNotes, note]);
              playedNotes.current = [...playedNotes.current, note];

              const correctFirst = correct.slice(0, playedNotes.current.length);
              if (playedNotes.current.length && JSON.stringify(playedNotes.current) !== JSON.stringify(correctFirst)) {
                toast.dismiss();
                toast('Das war falsch. Versuche es noch einmal.');
                playedNotes.current = [];
                return;
              }
              if (playedNotes.current.length === correct.length) {
                setSolved(true);
                setTimeout(() => {
                  onSolve();
                }, 3000);
                return;
              }
              toast.dismiss();
              toast(playedNotes.current.join(', '));
            }}
            stopNote={(midiNumber) => {
              piano.stop();
            }}
            width="100%"
            keyboardShortcuts={keyboardShortcuts}
          />
        </PianoContainer>
      </KeyboardContent>
    </KeyboardStyled>
  )
}

export default Keyboard;
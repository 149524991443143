import Level0 from "./Level0/Level0.jsx";
import Level1 from "./Level1/Level1.jsx";
import Level2 from "./Level2/Level2.jsx";
import Level3 from "./Level3/Level3.jsx";
import Level4 from "./Level4/Level4.jsx";
import LevelLocked from "./LevelLocked/LevelLocked.jsx";
import Level5 from "./Level5/Level5.jsx";
import Level6 from "./Level6/Level6.jsx";
import Level7 from "./Level7/Level7.jsx";
import Level8 from "./Level8/Level8.jsx";
import Level9 from "./Level9/Level9.jsx";
import Level10 from "./Level10/Level10.jsx";

const isDev = import.meta.env.DEV;
const Dummy = () => {
  return null;
}

const levelMap = {
  0: {
    name: "Level 1",
    component: Level0,
  },
  1: {
    name: "Level 1",
    component: Level1,
  },
  2: {
    name: "Level 2",
    component: Level2,
  },
  3: {
    name: "Level 3",
    component: Level3,
  },
  4: {
    name: "Level 4",
    component: Level4,
  },
  5: {
    name: "Level 5",
    component: Level5,
  },
  6: {
    name: "Level 6",
    component: Level6,
  },
  7: {
    name: "Level 7",
    component: Level7,
  },
  8: {
    name: "Level 8",
    component: Level8,
  },
  9: {
    name: "Level 9",
    component: Level9,
  },
  10: {
    name: "Level 10",
    component: Level10,
  },
}

const getLevel = (level, admin) => {
  const maxLevel = 7;

  if (level === 999) {
    return Dummy;
  }

  if ((level > maxLevel && !admin && !isDev)) {
    return LevelLocked;
  }

  if (!(level in levelMap)) {
    return LevelLocked;
  }

  return levelMap[level].component;
}

export default getLevel;
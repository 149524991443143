import Stage from "../../base/Stage/Stage.jsx";
import Marker from "../../../Marker.jsx";
import Details from "../../base/Details/Details.jsx";
import AnimatedRoute from "../../base/AnimatedRoute/AnimatedRoute.jsx";
import {useContext, useEffect, useState} from "react";
import {useLocation} from "wouter";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";

import introAudio from './audio/level8_intro.mp3';
import kuckuckAudio from './audio/kuckuck.mp3';
import Mischpult1 from "../Level3/Mischpult1.jsx";
import Clock from "./Clock.jsx";

const Level8 = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [solved, setSolved] = useState(false);
  const [location, setLocation] = useLocation();
  const {setLevel, saveLevelTime} = useContext(AppContext);

  useEffect(() => {
    if (showIntro && location === '/') {
      setTimeout(() => {
        setLocation('/intro');
        setShowIntro(false);
      }, 1000);
    }

  }, [showIntro])

  useEffect(() => {
    saveLevelTime(1);
  }, [])

  return (
    <>
      <Stage lightsOn>
        <Marker
          top={50}
          left={27}
          href={'/intro'}
        />
        <Marker
          top={40}
          left={70}
          message="Hier ist keine Uhrzeit."
        />
        <Marker
          top={50}
          left={60}
          message={solved ? null : "Du brauchst eine Zahl, um sie auf dem Mischpult einzustellen."}
          href={solved ? `/details/3` : null}
        />
        <Marker
          top={40}
          left={80}
          message="Ohh das ist wirklich ein schöner Weihnachtsbaum."
        />
        <Marker
          top={36}
          left={51}
          href={'/details/1'}
        />
        <Marker
          top={55}
          left={13}
          message="Das hilft Dir nicht weiter."
        />
        <Marker
          top={80}
          left={63}
          message="Das ist nur Papier."
        />
        <Marker
          top={82}
          left={38}
          message="Hier kann man keine Uhrzeit einstellen."
        />
      </Stage>
      <AnimatedRoute path={`/intro`}>
        <Details
          title="Kapitel 8: Der Klang der Kuckucksuhr"
          interstitial
          audio={introAudio}
          text={`<p>
            Weihnachtsmann aus dem Radio:<br/> 
            „Ich weiß es ganz genau... Du bist fast am Ziel und kannst mich auf diesem Weg befreien. 
            Ich glaube als Nächstes solltest Du Dir die Uhrzeit einmal genauer anschauen.
            </p>
            <p> 
            Dazu müsstest Du wissen: Meine große Weihnachtsshow an Heiligabend startet um 20 Uhr 
            und dauert 360 Minuten.“
            </p>`}
        />
      </AnimatedRoute>
      <AnimatedRoute path={`/details/1`}>
        <Details
          title="Die Kuckucksuhr"
          interstitial={solved}
          tip="Die Weihnachtsshow startet um 20:00 Uhr und dauert 360 Minuten. Wie spät ist es dann?"
          tipId={8}
          audio={solved ? kuckuckAudio : null}
          text={solved ? `<p>
Kuckuck, Kuckuck, Kuckuck, Kuckuck, Kuckuck, Kuckuck, Kuckuck, Kuckuck! 
</p>` : null}
        >
          <Clock onSolve={() => {
            setSolved(true);
          }}/>
        </Details>
      </AnimatedRoute>
      <AnimatedRoute path={`/details/3`}>
        <Details
          title="Das magische Mischpult"
          tip="Wie oft kam der Kuckuck heraus?"
          tipId={9}
        >
          <Mischpult1
          active={4}
          start={[9, 6, 5, 0]}
            onSolved={() => {
            saveLevelTime(2);
            setLevel(9);
            setLocation('/');
          }}/>
        </Details>
      </AnimatedRoute>
    </>
  )
}

export default Level8;
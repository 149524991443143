import styled from "styled-components";
import clockImages from './assets/clock.jpg?w=1000;1600';
import vogelImages from './assets/clock_vogel.jpg?w=1000;1600';
import minuteHand from './assets/minute_hand.svg';
import hourHand from './assets/hour_hand.svg';

import media from "../../../utilities/media.js";
import {useEffect, useState, useRef} from "react";
import Sparkles from "../../atoms/Sparkles/Sparkles.jsx";

const ClockStyled = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const ClockContent = styled.div`
  position: relative;
  width: 100%;
  pointer-events: none;
`;

const ClockImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
  pointer-events: none;
`;

const MinuteHand = styled.img`
  position: absolute;
  top: 17%;
  left: 47.8%;
  height: 30%;
  pointer-events: none;
`;

const HourHand = styled.img`
  position: absolute;
  top: 27%;
  left: 47.5%;
  height: 20%;
  pointer-events: none;

  transform-origin: bottom center;
`;

const ClockCenter = styled.div`
  position: absolute;
  top: 47%;
  left: 50.5%;
  width: 1px;
  height: 1px;
`;

const Clock = ({onSolve}) => {
  const [solved, setSolved] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [angle, setAngle] = useState(0);
  const [thingStyles, setThingStyles] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  })

  const centerRef = useRef(null);

  useEffect(() => {
      if (solved) {
        setTimeout(() => {
          onSolve();
        }, 1500)
      }
    },
    [solved]
  );

  const roundedAngle = Math.round(angle / 6) * 6;

  useEffect(() => {
    if (roundedAngle === 60 && !dragging) {
      setSolved(true);
    }
  }, [roundedAngle, dragging]);

  return (
    <ClockStyled
      onMouseDown={e => {
        setDragging(true)
        const clockCenter = centerRef.current.getBoundingClientRect();
        const angle = Math.atan2(e.clientY - clockCenter.top, e.clientX - clockCenter.left) * 180 / Math.PI + 90;
        setAngle(angle);
      }}
      onMouseUp={e => setDragging(false)}
      onMouseMove={(e) => {
        if (dragging) {
          const clockCenter = centerRef.current.getBoundingClientRect();
          const angle = Math.atan2(e.clientY - clockCenter.top, e.clientX - clockCenter.left) * 180 / Math.PI + 90;
          setAngle(angle);
        }
      }}
    >
      <ClockContent>
        {solved ? (
          <Sparkles/>
        ) : null}
        <ClockImg src={solved ? vogelImages[1] : clockImages[1]}/>
        <ClockCenter ref={centerRef}/>
        {solved ? null : (
          <>
            <MinuteHand src={minuteHand}/>
            <HourHand src={hourHand} style={{
              transform: `rotate(${roundedAngle}deg)`,
            }}/>
          </>
        )}
      </ClockContent>
    </ClockStyled>
  )
}

export default Clock;
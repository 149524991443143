import styled from "styled-components";

import startImages from './assets/startscreen_bg.jpg?w=1000;1600';
import {headline1, topline} from "../../../styles/typography.js";
import media from "../../../utilities/media.js";
import AudioSwitch from "../../atoms/AudioSwitch/AudioSwitch.jsx";
import Button from "../../atoms/Button/Button.jsx";
import TextContent from "../../atoms/TextContent/TextContent.jsx";

const StartScreenStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: calc(100vh - 50px);
  text-align: center;
`;

const BackgroundImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  padding: 20px;
  
  ${media.moreThan('lg')} {
    top: 40px;
    left: 10%;
    width: 50%;
    height: 80%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Headline = styled.h1`
  ${headline1}
`;

const SwitchContainer = styled.div`
  padding: 20px 0;
`;

const Topline = styled.div`
  ${topline};
  width: 100%;
  margin-bottom: 20px;
`;

const StartScreen = ({ onStart }) => {
  return (
    <StartScreenStyled>
      <BackgroundImage src={startImages[1]} />
      <Content>
        <Topline>RMS Escape Game</Topline>
        <Headline>Das Haus der Stille</Headline>
        <TextContent text={`
        <p>Herzlich willkommen, bist Du bereit das Geheimnis des stillen Hauses zu lösen? 
        Stelle Deine Fähigkeiten als Weihnachtsheld und Rätsellöser unter Beweis. Jetzt mitmachen und gewinnen.</p>
        `}/>
        <SwitchContainer>
          <AudioSwitch large/>
        </SwitchContainer>
        <Button onClick={() => onStart() }>
          Jetzt starten
        </Button>
      </Content>
    </StartScreenStyled>
  )
}

export default StartScreen;